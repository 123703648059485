//import chroma from "chroma-js";
import { FillLayerSpecification, SpriteSpecification, StyleSpecification } from "maplibre-gl";
import { layersWithCustomTheme } from "protomaps-themes-base";
import { Theme } from "protomaps-themes-base";

export default ({
  url,
  lang,
  glyphs,
  maxzoom,
  theme,
  sprite,
  buildings3D,
  buildingsDetour,
}: {
  url: string;
  lang: string;
  glyphs?: string;
  maxzoom: number;
  theme: Theme;
  sprite?: SpriteSpecification;
  buildings3D?: boolean;
  buildingsDetour?: boolean;
}): StyleSpecification => {
  const source = "protomaps";

  const layers = layersWithCustomTheme(source, theme, lang);
  /*layers.forEach(layer => {
    if (layer.id == 'earth' || layer.id == 'water') {
      (layer as FillLayerSpecification).filter = ["==", ["geometry-type"], "Polygon"]
    }
  })*/
  if (buildingsDetour) {
    // rajout d'un détour autour des buildings
    /*layers.forEach(layer => {
              if (layer.id == 'buildings') {
                  layer.paint = {
                      ...layer.paint,
                      "fill-opacity": 1
                  }
              }
          })*/
    layers.push({
      id: "buildings-detour",
      type: "line",
      source: source,
      "source-layer": "buildings",
      paint: {
        "line-color": theme.background,
        "line-width": 1,
      },
    });
  }
  if (buildings3D) {
    layers.push({
      id: "buildings-3d",
      source: source,
      "source-layer": "buildings",
      type: "fill-extrusion",
      minzoom: 15,
      paint: {
        "fill-extrusion-color": [
          "interpolate",
          ["linear"],
          ["coalesce", ["get", "height"], 0],
          0,
          theme.buildings,
          200,
          theme.buildings, // chroma(customTheme.buildings).saturate(0.5).hex(),
          400,
          theme.buildings, //chroma(customTheme.buildings).saturate(1).hex(),
        ],
        "fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 16, ["get", "height"]],
        "fill-extrusion-base": ["case", [">=", ["get", "zoom"], 16], ["get", "min_height"], 0],
        "fill-extrusion-opacity": 0.6,
      },
    });
  }

  return {
    version: 8,
    glyphs,
    sprite,
    sources: {
      protomaps: {
        type: "vector",
        maxzoom: maxzoom,
        attribution:
          'Basemap <a href="https://github.com/protomaps/basemaps">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
        ...(url.indexOf("pmtiles") > -1
          ? {
              url: "pmtiles://" + url,
            }
          : {
              tiles: [url],
            }),
      },
    },
    layers: layers,
  };
};
