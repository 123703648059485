import { ControlPosition, IControl, useControl } from "@vis.gl/react-maplibre";
import { Map as MaplibreMap, StyleSpecification } from "maplibre-gl";
import { memo } from "preact/compat";
import { useEffect } from "preact/hooks";
import "./StyleSwitcherControl.css";

export interface LayerSwitcherOptions {
  position: ControlPosition;
  expandDirection?: "up" | "down" | "right" | "left";
  mouseHover?: boolean;
  initialStyle: string;
  styles: {
    [key: string]: {
      className: string;
      style?: StyleSpecification;
    };
  };
}

class layerSwitcherControl implements IControl {
  _map: MaplibreMap | undefined;
  _container: HTMLElement;
  _options: LayerSwitcherOptions;
  _currentStyleId: string | undefined;
  constructor(options: LayerSwitcherOptions) {
    this._options = { ...options };
    this._container = document.createElement("div");
    this._container.classList.add("maplibregl-ctrl");
    this._container.classList.add("maplibregl-ctrl-basemaps");
    this._container.classList.add("closed");
    switch (this._options.expandDirection) {
      case "up":
        this._container.classList.add("reverse", "column");
        break;
      case "down":
        this._container.classList.add("column");
        break;
      case "left":
        this._container.classList.add("reverse");
    }
    if (this._options.mouseHover) {
      this._container.addEventListener("mouseenter", () => {
        this._container.classList.remove("closed");
      });
      this._container.addEventListener("mouseleave", () => {
        this._container.classList.add("closed");
      });
    } else {
      this._container.addEventListener("click", (event: MouseEvent) => {
        if (this._container.classList.contains("closed")) {
          this._container.classList.remove("closed");
          event.preventDefault();
        } else {
          this._container.classList.add("closed");
        }
      });
    }
  }

  onAdd(map: MaplibreMap) {
    this._map = map;
    this.changeStyles();
    return this._container;
  }

  changeStyles() {
    const styles = this._options.styles;
    Object.keys(styles).forEach((styleId) => {
      const base = styles[styleId];
      const basemapContainer = document.createElement("img");
      basemapContainer.classList.add("basemap", base.className);
      basemapContainer.dataset.id = styleId;
      basemapContainer.addEventListener("click", () => {
        const activeElement = this._container.querySelector(".active");
        activeElement?.classList.remove("active");
        basemapContainer.classList.add("active");
        if (basemapContainer != activeElement && (this._options.mouseHover || !this._container.classList.contains("closed"))) {
          this._map?.setStyle(this._options.styles[styleId].style as StyleSpecification);
          this._currentStyleId = styleId;
        }
      });
      basemapContainer.classList.add("hidden");
      this._container.appendChild(basemapContainer);
      if (this._options.initialStyle === styleId) {
        basemapContainer.classList.add("active");
      }
    });
  }

  onRemove() {
    this._container.parentNode?.removeChild(this._container);
    delete this._map;
  }
}

const f = (props: LayerSwitcherOptions) => {
  const t: layerSwitcherControl = useControl<layerSwitcherControl>(() => new layerSwitcherControl(props), {
    position: props.position,
  });

  useEffect(() => {
    t._options.styles = props.styles;
    if (t._currentStyleId && !props.styles[t._currentStyleId]) {
      delete t._currentStyleId;
    }
    t._map?.setStyle(props.styles[t._currentStyleId ?? props.initialStyle].style as StyleSpecification);
    t._currentStyleId = props.initialStyle;
  }, [props.styles]);

  return null;
};

export default memo(f);
