import {useEffect} from 'preact/hooks';
import {useControl} from '@vis.gl/react-maplibre';
import { applyReactStyle } from '@vis.gl/react-maplibre/src/utils/apply-react-style'
import type {ControlPosition} from '@vis.gl/react-maplibre';
import { memo } from 'preact/compat';

export type GlobeControlProps = {
  /** Id of the DOM element which should be made full screen. By default, the map container
   * element will be made full screen. */
  containerId?: string;
  /** Placement of the control relative to the map. */
  position?: ControlPosition;
  /** CSS style override, applied to the control's container */
  style?: React.CSSProperties;
};

function _GlobeControl(props: GlobeControlProps) {
  const ctrl = useControl(
    ({mapLib}) =>
      new mapLib.GlobeControl({
        container: props.containerId && document.getElementById(props.containerId)
      }),
    {position: props.position}
  );

  useEffect(() => {
    applyReactStyle(ctrl._controlContainer, props.style!);
  }, [props.style]);

  return null;
}

export const GlobeControl = memo(_GlobeControl);